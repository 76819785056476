import React, { Fragment, useEffect, useState } from 'react';
import MetaTags from 'react-meta-tags';
import {
  Card,
  Table,
  Spin,
  Menu,
  Dropdown,
  Pagination,
  Input,
  Button,
  Switch,
} from 'antd';
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom';
import { getUsers, promoteToAgentById } from '../../db/firebase';
import { SearchOutlined } from '@ant-design/icons';
import AssignCar from '../modals/AssignCar';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const history = useHistory();
  const params = new URLSearchParams(history.location.search);
  const [total, setTotal] = useState(0);

  const [current, setCurrent] = useState(params.get('current') || 1);
  const [pageSize, setPageSize] = useState(params.get('pageSize') || 10);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [value, setValue] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [clickedUser, setClickedUser] = useState({});

  useEffect(() => {
    fetchUsers();
    history.push({
      pathname: '/admin/users',
      search: `?current=${current}&pageSize=${pageSize}`,
    });
  }, [current, pageSize]);

  const menu = (value) => (
    <Menu>
      <Menu.Item key="Car Reservations">
        <Link
          rel="noopener noreferrer"
          to={`/admin/reservations?q=${value.uid}`}
        >
          Car Reservations
        </Link>
      </Menu.Item>
      {value?.roles && value?.roles.includes('agent') && (
        <Menu.Item key="assign">
          <Link
            rel="noopener noreferrer"
            to="#"
            onClick={() => handleCarAssigment(value)}
          >
            Assign Car
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'displayName',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (value) => value || 'ACTIVE',
    },
    {
      title: 'Role',
      render: (value) => {
        return (
          <Switch
            key={value?.uid}
            checkedChildren={
              <p
                className="font-semibold"
                style={{ fontSize: '10px', paddingRight: '10px' }}
              >
                Agent
              </p>
            }
            unCheckedChildren={
              <p
                className="text-xs font-semibold text-black"
                style={{ fontSize: '10px' }}
              >
                Agent
              </p>
            }
            onChange={(checked) => promoteToAgent(checked, value)}
            defaultChecked={value?.roles && value?.roles.includes('agent')}
            // loading={updatedRow === value?.id}
          />
        );
      },
      key: 'action-edit',
    },
    {
      title: '',
      render: (value) => {
        return (
          <div className="w-12">
            <Dropdown.Button type="primary" overlay={menu(value)}>
              Options
            </Dropdown.Button>
          </div>
        );
      },
      key: 'action',
    },
  ];

  const handleCarAssigment = (data) => {
    setModalVisible(true);
    setClickedUser(data);
  };

  const fetchUsers = async () => {
    setLoading(true);
    let res = await getUsers();

    if (res) {
      // Set the original and filtered users
      setOriginalUsers(res);
      setTotal(res.length);

      // If a search query exists, filter the users based on the current search value
      if (value) {
        const filteredData = res.filter((entry) =>
          entry?.displayName?.toLowerCase().includes(value.toLowerCase()),
        );
        setUsers(filteredData);
      } else {
        setUsers(res);
      }
    } else {
      console.log(res);
      setError('Server error, try again later.');
    }

    setLoading(false);
  };

  async function promoteToAgent(checked, value) {
    setLoading(true);

    // Promote the user to agent by UID
    await promoteToAgentById(checked, value?.uid);

    // Fetch updated users after promoting
    await fetchUsers();

    setLoading(false);
  }

  const handleSearch = (e) => {
    const currValue = e.target.value;
    setValue(currValue);
    if (currValue === '') {
      setUsers(originalUsers);
    } else {
      const filteredData = originalUsers.filter((entry) =>
        entry?.displayName?.toLowerCase().includes(currValue.toLowerCase()),
      );
      setUsers(filteredData);
    }
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Mrara Enterprises | Users</title>
        <meta name="description" content="#" />
      </MetaTags>
      <section className="mt-24" style={{ minHeight: '20rem' }}>
        {!loading && !error && (
          <>
            {/* <div className="flex pl-2 md:pl-6 items-center w-full md:w-5/6 mx-auto">
              
            </div> */}
            {/* <Card> */}
            <div className="flex flex-col items-end w-full md:w-11/12 lg:w-5/6 mx-auto px-2">
              <div className="flex flex-col md:flex-row justify-between w-full gap-4">
                <h1 className="text-4xl">Users</h1>
                <Input
                  placeholder="Search User Name"
                  value={value}
                  onChange={handleSearch}
                  className="w-3/4 md:w-1/4 mb-4 shadow-sm rounded-xl"
                  prefix={<SearchOutlined />}
                  size="large"
                />
              </div>

              <Table
                className="min-w-sm w-full overflow-x-auto rounded-xl shadow-lg"
                pagination={false}
                dataSource={users.slice(
                  (current - 1) * pageSize,
                  (current - 1) * pageSize + pageSize,
                )}
                columns={columns}
              />
              {!value && (
                <Pagination
                  onChange={(newPage, newPageSize) => {
                    setCurrent(newPage);
                    setPageSize(newPageSize);
                  }}
                  className="my-4 mr-2"
                  current={current}
                  defaultCurrent={current}
                  pageSize={pageSize}
                  total={total}
                  pageSizeOptions={['2', '5', '10', '15']}
                  showTotal={() => `Total ${total} Items`}
                  showSizeChanger
                />
              )}
            </div>
            {/* </Card> */}
          </>
        )}
        {loading && (
          <div className="w-full mt-32 flex justify-center">
            <Spin size="large" />
          </div>
        )}
        {error && <p className="text-center text-lg text-gray-700">{error}</p>}
      </section>
      <AssignCar
        visible={modalVisible}
        setVisible={setModalVisible}
        clickedUser={clickedUser}
        setClickedUser={setClickedUser}
        fetchUsers={fetchUsers}
      />
    </Fragment>
  );
};

export default Users;
