import React, { useContext, useEffect, useState } from 'react';
import { Card, Spin, Modal, Skeleton, Tooltip, Button } from 'antd';
import { FiArrowLeft, FiInfo } from 'react-icons/fi';
import { TbArrowBadgeRightFilled } from 'react-icons/tb';
import { MdOutlineDirectionsCar } from 'react-icons/md';
import { RiUserLine } from 'react-icons/ri';
import moment from 'moment';
import DetailedReceipt from './DetailedReceipt';
import PreTripPhotos from './PreTripPhotos';
import PostTripPhotos from './PostTripPhotos';
import ModifyTripModal from '../modals/ModifyTripModal';
import UserContext from '../../shared/context/UserContext';

export default function CarBookingDetail({
  reservation,
  setClickedRev,
  allRevs,
  handleBack,
  currentUser,
  loading,
  loggedinUser,
}) {
  const [modalVisible, setModalVisible] = useState(false);
  const [preTripPhotosVisible, setPreTripPhotosVisible] = useState(false);
  const [postTripPhotosVisible, setPostTripPhotosVisible] = useState(false);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [numberOfRevs, setNumberOfRevs] = useState(0);
  const [initialDailyTotal, setInitialDailyTotal] = useState(0);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const userRevs = allRevs?.filter((rev) => rev.owner === reservation.owner);
    setNumberOfRevs(userRevs.length);
  }, [allRevs]);

  useEffect(() => {
    if (reservation.modifyRequest === 'pending') {
      setRequestPending(true);
    } else {
      setRequestPending(false);
    }
  }, [reservation]);

  useEffect(() => {
    const start = reservation?.start?.split('T')[0];
    const end = reservation?.end?.split('T')[0];
    const bool = moment(reservation.created).isAfter(moment('12/20/2023'));
    const numDays = bool
      ? dayDifference(reservation?.start, reservation?.end)
      : Math.floor(moment.duration(moment(end).diff(moment(start))).asDays()) +
        1;
    setNumberOfDays(numDays);
    setInitialDailyTotal(
      parseFloat(
        (reservation?.pricePerDay + reservation?.insurancePerDay) *
          numDays *
          0.1346,
      )?.toFixed(2),
    );
  }, [reservation]);

  const handleModalVisible = () => {
    setModalVisible(!modalVisible);
  };

  const handlePreTripPhotosVisible = () => {
    setPreTripPhotosVisible(!preTripPhotosVisible);
  };

  const handlePostTripPhotosVisible = () => {
    setPostTripPhotosVisible(!postTripPhotosVisible);
  };

  function dayDifference(date1, date2) {
    let d1 = new Date(date1);
    let d2 = new Date(date2);
    let diff = Math.abs(d1 - d2);
    let days = Math.floor(diff / (1000 * 60 * 60 * 24));
    let hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (hours >= 1) {
      days++;
    }
    return days;
  }

  const renderTimeLeft = (type) => {
    const dayDifference = Math.floor(
      moment.duration(moment(reservation[type]).diff(moment())).asDays(),
    );
    const hourDifference = Math.floor(
      moment.duration(moment(reservation[type]).diff(moment())).asHours(),
    );
    const minuteDifference = Math.floor(
      moment.duration(moment(reservation[type]).diff(moment())).asMinutes(),
    );
    return dayDifference >= 1
      ? `${dayDifference} day${dayDifference > 1 ? 's' : ''}`
      : hourDifference >= 1
      ? `${hourDifference} hour${hourDifference > 1 ? 's' : ''}`
      : `${minuteDifference} minute${minuteDifference > 1 ? 's' : ''}`;
  };

  const handleClose = () => {
    setModifyModalVisible(!modifyModalVisible);
  };

  return (
    <Skeleton loading={loading} active>
      <Card className="h-auto rounded-lg shadow">
        {Object.keys(reservation)?.length !== 0 &&
        Object.keys(currentUser)?.length !== 0 ? (
          <>
            <div
              to="/u"
              className="absolute top-3 left-4 flex items-center text-gray-600 hover:text-gray-800 font-semibold text-lg cursor-pointer"
              onClick={handleBack}
            >
              <FiArrowLeft className="mr-1" /> Back
            </div>
            <div className="flex flex-col lg:flex-row justify-between mt-3 md:mt-6">
              <p className="font-bold text-3xl text-black mb-2 md:mb-6 md:pt-4">
                {reservation.status === 'Trip Ended'
                  ? 'Trip Ended'
                  : 'Booked Trip'}
              </p>
              <div className="flex flex-col gap-1">
                <div>
                  <p className="font-semibold text-gray-800">
                    {reservation?.carName}
                  </p>
                </div>
                <div
                  className="bg-cover bg-center md:w-32 w-24 h-16 mb-1 rounded"
                  style={{
                    backgroundImage: `url(${reservation?.src})`,
                  }}
                ></div>
              </div>
            </div>
            <hr />
            <div className="flex flex-col md:flex-row md:justify-between w-full">
              <div>
                <div className="mt-2 flex flex-col">
                  <p className="font-bold">
                    {`${reservation?.ownerName?.split(' ')[0]}'s Trip`}
                  </p>
                  <div className="flex gap-2">
                    <div>
                      <p className="font-bold text-lg">
                        {moment(reservation?.start).format('ddd, MMM D')}
                      </p>
                      <p className="text-gray-500">
                        {moment(reservation?.start).format('h:mm a')}
                      </p>
                    </div>
                    <div className="pt-2">
                      <TbArrowBadgeRightFilled className="text-2xl" />
                    </div>

                    <div>
                      <p className="font-bold text-lg">
                        {moment(reservation?.end).format('ddd, MMM D')}
                      </p>
                      <p className="text-gray-500">
                        {moment(reservation?.end).format('h:mm a')}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="md:mt-6 mt-4 flex flex-col">
                  <p className="font-bold mb-2">LOCATION</p>
                  <div className="flex gap-2">
                    <div className="p-1 rounded-full border">
                      <MdOutlineDirectionsCar className="text-2xl text-gray-700" />
                    </div>
                    <p className="pt-2 font-bold text-gray-600 text-xs">
                      {reservation?.location}
                    </p>
                  </div>
                </div>
                <div className="md:mt-10 mt-4">
                  <p className="font-bold">TOTAL</p>
                  <p className="font-semibold text-gray-600">
                    ${reservation?.total}
                  </p>
                  <p
                    className="font-semibold text-xs text-blue-500 underline cursor-pointer hover:text-blue-600"
                    onClick={handleModalVisible}
                  >
                    View detailed receipt
                  </p>
                </div>
              </div>
              <div>
                {reservation.status !== 'Trip Ended' && (
                  <div className="mt-4 md:mt-2 border px-3 py-2 flex flex-col gap-3 mx-auto md:mx-0 md:w-80 w-auto rounded">
                    {reservation.status === 'Payment Received' ||
                    reservation.status === 'Test Payment Received' ? (
                      <p className="text-gray-600 text-l">
                        This trip starts in {renderTimeLeft('start')}. You're
                        checked in.
                      </p>
                    ) : (
                      <p className="text-gray-600 text-l">
                        This trip ends in {renderTimeLeft('end')}.
                      </p>
                    )}
                    {loggedinUser?.uid === reservation?.owner && (
                      <Button
                        className="px-6 py-1 flex-1 font-bold border-2 border-black hover:bg-transparent hover:text-primary-color hover:shadow-none hover:border-2 hover:border-primary-color"
                        onClick={() => {
                          setModifyModalVisible(true);
                        }}
                        disabled={requestPending}
                      >
                        {requestPending ? 'Request Pending' : 'Extend Trip'}
                      </Button>
                    )}
                  </div>
                )}
                {reservation.status === 'Trip Ended' &&
                  loggedinUser?.uid === reservation?.owner && (
                    <Button
                      className="w-full mt-4 px-6 py-1 flex-1 font-bold border-2 border-black hover:bg-transparent hover:text-primary-color hover:shadow-none hover:border-2 hover:border-primary-color"
                      onClick={() => {
                        setModifyModalVisible(true);
                      }}
                      disabled={requestPending}
                    >
                      {requestPending ? 'Request Pending' : 'Extend Trip'}
                    </Button>
                  )}
                <div className="mt-6 flex gap-3">
                  <div>
                    <div className="p-1 rounded-full border">
                      <RiUserLine className="text-3xl" />
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <p className="font-bold">{reservation.ownerName}</p>
                    {(user?.roles?.includes('agent') ||
                      user?.roles?.includes('admin')) && (
                      <p className="text-xs font-bold text-gray-600">
                        {numberOfRevs} {numberOfRevs > 1 ? 'Trips' : 'Trip'}
                      </p>
                    )}
                    <p className="text-xs font-bold text-gray-600">
                      {currentUser?.phoneNumber}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 mt-6">
                  <button
                    className="px-6 py-1 flex-1 font-bold border-2 border-black transition-colors duration-300 hover:bg-gray-200"
                    onClick={handlePreTripPhotosVisible}
                  >
                    Pre-Trip Photos
                  </button>
                  <button
                    className="px-6 py-1 flex-1 font-bold border-2 border-black transition-colors duration-300 hover:bg-gray-200"
                    onClick={handlePostTripPhotosVisible}
                  >
                    Post-Trip Photos
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="w-full mt-32 flex justify-center">
            <Spin size="large" />
          </div>
        )}

        <Modal
          title="Detailed Receipt"
          visible={modalVisible}
          onCancel={handleModalVisible}
          footer={null}
        >
          <DetailedReceipt
            reservation={reservation}
            numberOfDays={numberOfDays}
            initialDailyTotal={initialDailyTotal}
          />
        </Modal>
      </Card>
      <PreTripPhotos
        visible={preTripPhotosVisible}
        onCancel={handlePreTripPhotosVisible}
        reservation={reservation}
      />
      <PostTripPhotos
        visible={postTripPhotosVisible}
        onCancel={handlePostTripPhotosVisible}
        reservation={reservation}
      />
      <ModifyTripModal
        visible={modifyModalVisible}
        handleClose={handleClose}
        reservation={reservation}
        setClickedRev={setClickedRev}
        allRevs={allRevs}
      />
    </Skeleton>
  );
}
