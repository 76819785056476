import { initializeApp } from 'firebase/app';

import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  Timestamp,
  updateDoc,
  doc,
  getDoc,
  arrayUnion,
  arrayRemove,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore';

import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  // updateProfile,
} from 'firebase/auth';
import {
  getStorage,
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from 'firebase/storage';
import { message } from 'antd';
import { nanoid } from 'nanoid';
import { getToken, onMessage, getMessaging } from 'firebase/messaging';
import { getFunctions, httpsCallable } from 'firebase/functions';
import moment from 'moment';

const firebaseConfig = {
  apiKey: 'AIzaSyDJuI4bdLjo-jhjxsjeIWDxHBon1oupMIs',
  authDomain: 'mrarastr.firebaseapp.com',
  projectId: 'mrarastr',
  storageBucket: 'mrarastr.appspot.com',
  messagingSenderId: '199529048798',
  appId: '1:199529048798:web:878a6d4a454fa7748d304f',
  measurementId: 'G-WDDZEP2SFP',
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
const storage = getStorage(app);
const functions = getFunctions(app);
export const messaging = getMessaging(app);
// connectFunctionsEmulator(functions, 'localhost', 5001);

const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

export const getDocuments = async (type) => {
  try {
    const q = query(collection(db, type));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    return values.filter((value) => value.status !== 'DELETED');
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = async () => {
  try {
    const q = query(collection(db, 'users'));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    return values.filter((value) => value.status !== 'DELETED');
  } catch (error) {
    console.log(error);
  }
};

export const getCars = async () => {
  try {
    const q = query(collection(db, 'cars'));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    return values.filter((value) => value.status !== 'INACTIVE');
  } catch (error) {
    console.log(error);
  }
};

export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, 'users'), where('uid', '==', user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        displayName: user.displayName,
        authProvider: 'google',
        email: user.email,
        status: 'ACTIVE',
        phoneNumber: '',
      });
    }
  } catch (err) {
    message.error(err.message);
  }
};
export const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    message.error(err.message);
  }
};
export const registerWithEmailAndPassword = async (userInfo) => {
  try {
    const res = await createUserWithEmailAndPassword(
      auth,
      userInfo.email,
      userInfo.password,
    );
    const user = res.user;
    await addDoc(collection(db, 'users'), {
      uid: user.uid,
      displayName: userInfo.name,
      authProvider: 'local',
      email: userInfo.email,
      status: 'ACTIVE',
      phoneNumber: userInfo.phoneNumber,
    });
    return res;
  } catch (err) {
    console.log(err);
    return err;
    // alert(err.message)
  }
};
export const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (err) {
    message.error(err.message);
    return false;
  }
};
export const logoutFirebase = () => {
  signOut(auth);
};

export const getUser = async (uid) => {
  try {
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const docs = await getDocs(q);
    const retDocs = [];
    docs.forEach((doc) => {
      retDocs.push(doc.data());
    });
    return retDocs.length ? retDocs[0] : null;
  } catch (error) {
    console.log(error);
  }
};

export const getCar = async (id) => {
  try {
    const q = query(collection(db, 'cars'), where('id', '==', id));
    const docs = await getDocs(q);
    const retDocs = [];
    docs.forEach((doc) => {
      retDocs.push(doc.data());
    });
    return retDocs.length ? retDocs[0] : null;
  } catch (error) {
    console.log(error);
  }
};

export const uploadCarImage = async (file) => {
  let nameArr = file.name.split('.');
  const storageDocuments = ref(
    storage,
    `carImages/${nanoid()}.${nameArr[nameArr.length - 1]}`,
  );
  let res = await uploadBytes(storageDocuments, file);

  let url = await getDownloadURL(res.ref);
  return url;
};

export const uploadTripImage = async (file, reservation, imageType) => {
  const nameArr = file.name.split('.');
  const revId = reservation?.id;
  const storageDocuments = ref(
    storage,
    `tripImages/${revId}/${nanoid()}.${nameArr[nameArr.length - 1]}`,
  );

  const res = await uploadBytes(storageDocuments, file);
  const url = await getDownloadURL(res.ref);
  const docRef = doc(db, 'car_reservations', revId);

  let updateField;
  if (imageType === 'preTripImages') {
    updateField = {
      preTripImages: [...(reservation?.preTripImages || []), url],
    };
  } else if (imageType === 'postTripImages') {
    updateField = {
      postTripImages: [...(reservation?.postTripImages || []), url],
    };
  }

  await updateDoc(docRef, updateField);
  return url;
};

export const deleteImage = async (url, reservation, imageType) => {
  const imageRef = ref(storage, url);
  deleteObject(imageRef)
    .then(() => {
      // console.log('Image deleted successfully');
    })
    .catch((error) => {
      console.error('Error deleting image:', error);
    });
  const reservationRef = doc(db, 'car_reservations', reservation?.id);
  if (reservationRef)
    return await updateDoc(reservationRef, {
      [imageType]: arrayRemove(url),
    });
  else throw Error("Car doesn't exist");
};

export const addCar = async (car) => {
  try {
    const id = nanoid();
    const newCar = await addDoc(collection(db, 'cars'), {
      ...car,
      id: id,
      carId: id,
      status: 'ACTIVE',
      disabledDates: [],
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });

    return newCar;
  } catch (error) {
    console.log('Firebase error: ', error);
  }
};

export const getCarById = async (id) => {
  try {
    const q = query(collection(db, 'cars'), where('id', '==', id));
    return await getDoc(q);
  } catch (error) {
    console.log(error);
  }
};

export const editCar = async (updates, id) => {
  const carRef = doc(db, 'cars', id);
  if (carRef) return await updateDoc(carRef, updates);
  else throw Error("Car doesn't exist");
};

export const deleteCarById = async (id) => {
  try {
    const carRef = doc(db, 'cars', id);
    await deleteDoc(carRef);
    console.log('Car document deleted successfully');
  } catch (error) {
    console.error('Error deleting car document: ', error);
    throw error;
  }
};

export const reserveCar = async (car) => {
  try {
    const reserveCarFunc = httpsCallable(functions, 'reserveCar');
    const res = await reserveCarFunc(car);
    if (res) return res.data;

    // console.log('***firebase function response***', res);
  } catch (err) {
    console.log(err, '***firebase error***');
    //apartment
  }
};

export const getAllCarReservations = async () => {
  try {
    const q = query(collection(db, 'car_reservations'));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    const rev = values.filter(
      (val) =>
        val.status === 'Payment Received' ||
        val.status === 'Active Trip' ||
        val.status === 'Trip Ended' ||
        // val.status === 'Test Payment Received' ||
        val.status === 'Test Active Trip',
    );
    rev.sort((a, b) => (a.created < b.created ? 1 : -1));
    return rev;
  } catch (error) {
    console.log(error);
  }
};

export const getCarReservations = async (user) => {
  try {
    const q = query(
      collection(db, 'car_reservations'),
      where('owner', '==', user.uid),
    );
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    const rev = values.filter(
      (val) =>
        val.status === 'Payment Received' ||
        // val.status === 'Test Payment Received' ||
        val.status === 'Active Trip' ||
        val.status === 'Trip Ended',
    );
    return rev.sort((a, b) => (a.created < b.created ? 1 : -1));
  } catch (error) {
    console.log(error);
  }
};

export const getCarReservationsByUserId = async (id) => {
  try {
    const q = query(
      collection(db, 'car_reservations'),
      where('owner', '==', id),
    );
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    const rev = values.filter(
      (val) =>
        val.status !== 'Initiated' ||
        (val.status === 'Initiated' &&
          moment(val.start).isSameOrAfter(moment())),
    );
    return rev.sort((a, b) => (a.created < b.created ? 1 : -1));
  } catch (error) {
    console.log(error);
  }
};

export const getCarReservationsByCarId = async (id) => {
  try {
    const q = query(collection(db, 'car_reservations'), where('car', '==', id));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    return values.filter(
      (a) =>
        moment(a.end) >= moment(new Date()) &&
        (a.status === 'Active Trip' ||
          a.status === 'Payment Received' ||
          // a.status === 'Test Payment Received' ||
          a.status === 'Test Active Trip'),
    );
  } catch (error) {
    console.log(error);
  }
};

export const disableDatesForCar = async (dates, id) => {
  const carRef = doc(db, 'cars', id);
  if (carRef)
    return await updateDoc(carRef, { disabledDates: arrayUnion(dates) });
  else throw Error("Car doesn't exist");
};

export const enableDatesForCar = async (dates, id) => {
  const carRef = doc(db, 'cars', id);
  if (carRef)
    return await updateDoc(carRef, { disabledDates: arrayRemove(dates) });
  else throw Error("Car doesn't exist");
};

export const getApartmentById = async (id) => {
  try {
    const apartmentRef = doc(db, 'apartments', id);
    const docSnap = await getDoc(apartmentRef);
    return docSnap.data();
  } catch (error) {
    console.log(error);
  }
};

export const getApartmentReservations = async (user) => {
  try {
    const q = query(
      collection(db, 'apartment_reservations'),
      where('owner', '==', user.uid),
    );
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data(), id: doc.id });
    });
    return values.sort((a, b) => (a.created < b.created ? 1 : -1));
  } catch (error) {
    console.log(error);
  }
};

export const addPhoneNumber = async (payload) => {
  try {
    const addPhoneNumber = httpsCallable(functions, 'addPhoneNumber');
    const res = await addPhoneNumber(payload);
    if (res) return res.data;
  } catch (err) {
    console.log(err, '***firebase error***');
  }
};

// export const reserveApartment = async () => {
//   try {
//     const reserveApartment = httpsCallable(functions, 'reserveApartment');
//     const res = await reserveApartment();
//     if (res) return res.data;
//   } catch (err) {
//     console.log(err, '***firebase error***');
//   }
// };

export const editApartment = async (updates, id) => {
  const apartmentRef = doc(db, 'apartments', id);
  if (apartmentRef) return await updateDoc(apartmentRef, updates);
  else throw Error("Apartment doesn't exist");
};

export const uploadApartmentImage = async (file) => {
  let nameArr = file.name.split('.');
  const storageDocuments = ref(
    storage,
    `apartmentImages/${nanoid()}.${nameArr[nameArr.length - 1]}`,
  );
  let res = await uploadBytes(storageDocuments, file);

  let url = await getDownloadURL(res.ref);
  return url;
};

export const addApartment = async (apartment) => {
  try {
    const newApartment = await addDoc(collection(db, 'apartments'), {
      ...apartment,
      id: nanoid(),
      status: 'ACTIVE',
      createdAt: Timestamp.now(),
      updatedAt: Timestamp.now(),
    });

    return newApartment;
  } catch (error) {
    console.log('Firebase error: ', error);
  }
};

export const fetchPreferences = async () => {
  try {
    const q = query(collection(db, 'preferences'));
    const querySnapshot = await getDocs(q);
    const values = [];
    querySnapshot.forEach((doc) => {
      values.push({ ...doc.data() });
    });
    return values;
  } catch (error) {
    console.log(error);
  }
};

export const updatePreferences = async (data) => {
  try {
    const preferencesRef = doc(db, 'preferences', '7g0j16Haudy3CHvih0at');

    await updateDoc(preferencesRef, data);

    console.log('Document updated successfully');
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

export const modifyTrip = async (data) => {
  try {
    const modifyTripCallable = httpsCallable(functions, 'modifyTrip');
    const res = await modifyTripCallable(data);
    console.log(res.data);
    if (res.data) return res.data;
  } catch (err) {
    console.log(err, '***firebase error***');
  }
};

export const getModifyRequest = async (id) => {
  try {
    const q = query(
      collection(db, 'modify_trip_requests'),
      where('id', '==', id),
    );
    const docs = await getDocs(q);
    const retDocs = [];
    docs.forEach((doc) => {
      retDocs.push(doc.data());
    });
    return retDocs.length ? retDocs[0] : null;
  } catch (error) {
    console.log(error);
  }
};

export const handleModifyRequest = async (data) => {
  try {
    const handleRequestCallable = httpsCallable(
      functions,
      'handleModifyRequest',
    );
    const res = await handleRequestCallable(data);
    console.log(res.data);
    if (res.data) return res.data;
  } catch (err) {
    console.log(err, '***firebase error***');
  }
};

export const requestForToken = async (uid) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        'BILD8ceDNR9NJdM3TZCjxOCFRrtNmJo5TMjVdISzyyVghANNFN4amPU5XleehdBtCFnovmJr-Vv9Bwn8EZZQjTo',
    });
    if (currentToken) {
      console.log('current token for client: ', currentToken);

      const q = query(collection(db, 'users'), where('uid', '==', uid));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If user document exists, update the tokens array
        querySnapshot.forEach(async (docSnapshot) => {
          const userDocRef = doc(db, 'users', docSnapshot.id);
          const userData = docSnapshot.data();

          if (
            !userData.fcmTokens ||
            !userData.fcmTokens.includes(currentToken)
          ) {
            await updateDoc(userDocRef, {
              fcmTokens: arrayUnion(currentToken),
            });
            console.log('Token added to Firestore');
          } else {
            console.log('Token already exists in Firestore');
          }
        });
      } else {
        console.log('No user is logged in');
      }
    } else {
      console.log(
        'No registration token available. Request permission to generate one.',
      );
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err);
  }
};

export const requestPermission = async (uid) => {
  console.log('Requesting permission...');
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    console.log('Notification permission granted.');
    requestForToken(uid);
  } else {
    console.log('Unable to get permission to notify.');
  }
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });

export const subscribeToAdminNotifications = (callback) => {
  const q = query(collection(db, 'notifications_admin'));
  return onSnapshot(
    q,
    (querySnapshot) => {
      const values = [];
      querySnapshot.forEach((doc) => {
        values.push({ ...doc.data(), id: doc.id });
      });
      callback(values);
    },
    (error) => {
      console.log('Error fetching notifications:', error);
    },
  );
};

export const subscribeToUserNotifications = (uid, callback) => {
  const userNotificationsRef = doc(db, 'notifications_user', uid);

  return onSnapshot(
    userNotificationsRef,
    (docSnapshot) => {
      const userNotificationsData = docSnapshot.data();
      const notifications = userNotificationsData?.notifications || [];
      callback(notifications);
    },
    (error) => {
      console.log('Error fetching user notifications:', error);
    },
  );
};

export const markNotificationsAsRead = async (
  notificationIds,
  uid, // User ID for user notifications
  isAdmin,
) => {
  try {
    if (isAdmin) {
      // Admin notifications
      const updates = notificationIds.map(async (id) => {
        const notificationRef = doc(db, 'notifications_admin', id);
        return updateDoc(notificationRef, { isRead: true });
      });
      await Promise.all(updates);
    } else {
      // User notifications
      const userNotificationsRef = doc(db, 'notifications_user', uid);
      const userNotificationsDoc = await getDoc(userNotificationsRef);

      if (userNotificationsDoc.exists()) {
        const notificationsData =
          userNotificationsDoc.data().notifications || [];

        // Update isRead field for the specified notifications
        const updatedNotifications = notificationsData.map((notification) => {
          if (notificationIds.includes(notification.id)) {
            return { ...notification, isRead: true };
          }
          return notification;
        });

        await updateDoc(userNotificationsRef, {
          notifications: updatedNotifications,
        });
      } else {
        console.error('No notifications document found for user:', uid);
      }
    }
  } catch (error) {
    console.error('Error updating notifications:', error);
  }
};

export const editReservation = async (updates, id) => {
  const reservationRef = doc(db, 'car_reservations', id);
  if (reservationRef) return await updateDoc(reservationRef, updates);
  else throw Error("Reservation doesn't exist");
};

export const promoteToAgentById = async (checked, uid) => {
  try {
    // Query the user by uid
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const docs = await getDocs(q);

    if (docs.empty) {
      console.log(`No user found with UID: ${uid}`);
      return;
    }

    // Assuming there's only one user document with the UID
    const userDoc = docs.docs[0].ref;

    // Add or create the roles field with 'agent'
    await updateDoc(userDoc, {
      roles: checked ? arrayUnion('agent') : '',
    });

    console.log('User roles updated successfully');
  } catch (error) {
    console.log('Error updating user roles:', error);
  }
};

export const assignCar = async (cars, uid) => {
  try {
    // Query the user by uid
    const q = query(collection(db, 'users'), where('uid', '==', uid));
    const docs = await getDocs(q);

    if (docs.empty) {
      console.log(`No user found with UID: ${uid}`);
      return;
    }

    const userDoc = docs.docs[0].ref;

    await updateDoc(userDoc, {
      assignedCars: cars,
    });

    console.log('Car assigned successfully');
  } catch (error) {
    console.log('Error assigning car:', error);
  }
};

// export const disableUser = async (payload) => {
//   try {
//     const manageUser = httpsCallable(functions, 'manageUser');
//     const res = await manageUser(payload);
//     console.log(res);
//     if (res) return res.data;
//   } catch (err) {
//     console.log(err, '***firebase error***');
//   }
// };

// export const enableUser = () => {
//   const user = auth.currentUser;
//   if (user) {
//     user
//       .updateProfile({ disabled: false })
//       .then(() => {
//         console.log('User enabled successfully');
//       })
//       .catch((error) => {
//         console.error('Error enabling user:', error);
//       });
//   }
// };
