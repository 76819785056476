import React, { useState, useEffect, useContext, useCallback } from 'react';
import CardWithImages from './CardWithImage';
import CarBookingDetail from './CarBookingDetail';
import moment from 'moment';
import { getUser } from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';

export default function EndedRevs({ revs, allRevs }) {
  const [clickedRev, setClickedRev] = useState({});
  const [formatted, setFormatted] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    setFormatted(formatRevs(revs, true));
  }, []);

  const handleShowDetail = (rev) => {
    setClickedRev(rev);
    getUserByID(rev?.owner);
  };

  const handleHideDetail = () => {
    setClickedRev({});
    setCurrentUser({});
  };
  async function getUserByID(id) {
    setLoading(true);
    await getUser(id)
      .then((res) => {
        setLoading(false);
        setCurrentUser(res);
      })
      .catch((err) => console.log(err));
  }

  const formatRevs = useCallback((data) => {
    let formatted = {};
    let sortedKeys = [];
    let sortedFormatted = [];
    data.forEach((rev) => {
      if (rev?.status === 'Trip Ended') {
        const formattedDate = moment(rev.start)
          .format('MMMM, YYYY')
          .toUpperCase();
        const time = `${moment(rev.start).format('MMM D')}-${moment(
          rev.end,
        ).format('MMM D')}`;
        formatted[formattedDate]
          ? formatted[formattedDate].push({ ...rev, time: time })
          : (formatted[formattedDate] = [{ ...rev, time: time }]);
      }
    });
    sortedKeys = Object.keys(formatted).sort((a, b) => {
      const dateA = moment(a, 'MMMM, YYYY');
      const dateB = moment(b, 'MMMM, YYYY');
      return dateA.isBefore(dateB) ? 1 : dateA.isAfter(dateB) ? -1 : 0;
    });
    sortedFormatted = {};
    sortedKeys.forEach((key) => {
      sortedFormatted[key] = formatted[key];
    });

    return sortedFormatted;
  });

  return (
    <>
      {Object.keys(clickedRev).length === 0 && (
        <p className="font-bold text-3xl text-black mb-6">History</p>
      )}

      {Object.keys(clickedRev).length === 0 ? (
        Object.keys(formatted).length !== 0 ? (
          Object.keys(formatted)?.map((key, index) => {
            return (
              <div key={index} className="w-full">
                <p className="font-bold mt-4">{key}</p>
                <hr className="my-3 w-11/12" />
                {formatted[key]?.map((rev, index) => (
                  <div
                    className="w-full"
                    key={index}
                    onClick={() => handleShowDetail(rev)}
                  >
                    <CardWithImages {...rev} />
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <p className="font-bold text-lg">No Records Found</p>
        )
      ) : (
        <CarBookingDetail
          reservation={clickedRev}
          allReservations={revs}
          setClickedRev={setClickedRev}
          handleBack={handleHideDetail}
          allRevs={allRevs}
          currentUser={currentUser}
          loading={loading}
          loggedinUser={user}
        />
      )}
    </>
  );
}
