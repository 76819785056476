import React, { useState, useEffect, useContext, useMemo } from 'react';
import CardWithImages from './CardWithImage';
import CarBookingDetail from './CarBookingDetail';
import moment from 'moment';
import {
  getAllCarReservations,
  getCarReservations,
  getUser,
} from '../../db/firebase';
import UserContext from '../../shared/context/UserContext';

export default function BookedRevs({ fetchData, revs, allRevs, numOfRevs }) {
  const [clickedRev, setClickedRev] = useState({});
  // const [formatted, setFormatted] = useState({});
  const [currentUser, setCurrentUser] = useState({});
  // const [car, setCar] = useState({});
  const { user } = useContext(UserContext);
  // const [reservations, setReservations] = useState([]);
  const [endedRevs, setEndedRevs] = useState([]);
  const [loading, setLoading] = useState(true);
  const isAdmin = user?.roles?.includes('admin');

  // useEffect(() => {
  //   setFormatted();
  // }, []);

  // useEffect(() => {
  //   if (Object.keys(clickedRev).length !== 0) {

  //     // getCarByID(clickedRev?.car);
  //   }
  // }, [clickedRev]);

  // useEffect(() => {
  //   fetchData().then((res) => {
  //     if (!res) {
  //       setReservations([]);
  //       setLoading(false);
  //       return;
  //     }
  //     const active = [];
  //     res.map((r) => {
  //       if (r.status !== 'Trip Ended') {
  //         active.push(r);
  //       }
  //     });
  //     setReservations(active);
  //     setLoading(false);
  //   });
  // }, []);

  // const fetchData = async () => {
  //   if (isAdmin) {
  //     return await getAllCarReservations();
  //   } else {
  //     return await getCarReservations(currentUser);
  //   }
  // };

  const handleShowDetail = (rev) => {
    setClickedRev(rev);
    getUserByID(rev?.owner);
  };

  const handleHideDetail = () => {
    setClickedRev({});
    setCurrentUser({});
    fetchData();
  };

  async function getUserByID(id) {
    setLoading(true);
    await getUser(id)
      .then((res) => {
        setLoading(false);
        setCurrentUser(res);
      })
      .catch((err) => console.log(err));
  }

  // async function getCarByID(id) {
  //   setLoading(true);
  //   await getCar(id)
  //     .then((res) => {
  //       setLoading(false);
  //       setCar(res);
  //     })
  //     .catch((err) => console.log(err));
  // }

  const formatRevs = (data) => {
    let formatted = {};
    let sortedKeys = [];
    let sortedFormatted = [];
    data?.forEach((rev) => {
      if (
        rev?.status === 'Payment Received' ||
        rev?.status === 'Test Payment Received'
      ) {
        const time = `Starting at ${moment(rev.start).format('h:mm a')}`;
        if (moment(rev?.start).isSame(moment(), 'day')) {
          formatted['TODAY']
            ? formatted['TODAY'].push({
                ...rev,
                time: time,
              })
            : (formatted['TODAY'] = [
                {
                  ...rev,
                  time: time,
                },
              ]);
        } else {
          const formattedDate = moment(rev.start)
            .format('dddd, MMMM D, YYYY')
            .toUpperCase();
          formatted[formattedDate]
            ? formatted[formattedDate].push({
                ...rev,
                time: time,
              })
            : (formatted[formattedDate] = [
                {
                  ...rev,
                  time: time,
                },
              ]);
        }
      } else if (
        rev?.status === 'Active Trip' ||
        rev?.status === 'Test Active Trip'
      ) {
        const time = `Ending at ${moment(rev.end).format('h:mm a')}`;
        if (moment(rev?.end).isSame(moment(), 'day')) {
          formatted['TODAY']
            ? formatted['TODAY'].push({
                ...rev,
                time: time,
              })
            : (formatted['TODAY'] = [
                {
                  ...rev,
                  time: time,
                },
              ]);
        } else {
          const formattedDate = moment(rev.end)
            .format('dddd, MMMM D, YYYY')
            .toUpperCase();
          formatted[formattedDate]
            ? formatted[formattedDate].push({
                ...rev,
                time: time,
              })
            : (formatted[formattedDate] = [
                {
                  // carName: rev.carName,
                  // location: rev.location,
                  // ownerName: rev.ownerName,
                  ...rev,
                  time: time,
                },
              ]);
        }
      }
    });
    sortedKeys = Object.keys(formatted).sort((a, b) => {
      const dateA = a === 'TODAY' ? moment() : moment(a, 'dddd, MMMM D, YYYY');
      const dateB = b === 'TODAY' ? moment() : moment(b, 'dddd, MMMM D, YYYY');
      return dateA.isBefore(dateB) ? -1 : dateA.isAfter(dateB) ? 1 : 0;
    });
    sortedFormatted = {};
    sortedKeys.forEach((key) => {
      sortedFormatted[key] = formatted[key];
    });

    return sortedFormatted;
  };

  const formatted = useMemo(() => formatRevs(revs), [revs]);

  return (
    <>
      {Object.keys(clickedRev).length === 0 && (
        <div className="flex md:flex-row flex-col w-full justify-between md:pr-10 lg:pr-32">
          <p className="font-bold text-3xl text-black mb-6">Booked</p>
          {user?.roles?.includes('admin') && (
            <p className="font-semibold text-lg text-black mb-6 md:pt-4">
              {numOfRevs} Bookings
            </p>
          )}
        </div>
      )}
      {
        Object.keys(clickedRev).length === 0 ? (
          Object.keys(formatted).length !== 0 ? (
            Object.keys(formatted)?.map((key, index) => {
              return (
                <div key={index} className="w-full">
                  <p className="font-bold mt-4">{key}</p>
                  <hr className="my-3 w-11/12" />
                  {formatted[key]?.map((rev, index) => (
                    <div
                      className="w-full"
                      key={index}
                      onClick={() => handleShowDetail(rev)}
                    >
                      <CardWithImages {...rev} />
                    </div>
                  ))}
                </div>
              );
            })
          ) : (
            <p className="font-bold text-lg">No Records Found</p>
          )
        ) : (
          // loading ? (
          //   <Spin />
          // ) : (
          <CarBookingDetail
            reservation={clickedRev}
            setClickedRev={setClickedRev}
            allReservations={revs}
            handleBack={handleHideDetail}
            currentUser={currentUser}
            loading={loading}
            allRevs={allRevs}
            loggedinUser={user}
          />
        )
        // )
      }
    </>
  );
}
