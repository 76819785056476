import React, { useState, useEffect } from 'react';
import { Button, Modal, Checkbox, Spin, message, Input } from 'antd';
import { assignCar, getCars } from '../../db/firebase';
import { SearchOutlined } from '@ant-design/icons';

const AssignCar = ({
  visible,
  setVisible,
  clickedUser,
  setClickedUser,
  fetchUsers,
}) => {
  const [status, setStatus] = useState('idle'); // Status for fetching cars
  const [assigning, setAssigning] = useState(false); // Status for assigning cars
  const [cars, setCars] = useState([]);
  const [filteredCars, setFilteredCars] = useState([]); // For the filtered list
  const [selectedCars, setSelectedCars] = useState([]);
  const [searchQuery, setSearchQuery] = useState(''); // New state for search query

  // Fetch cars when the modal becomes visible
  useEffect(() => {
    if (visible) {
      fetchCars();
    }
  }, [visible]);

  // Fetch cars and set state
  const fetchCars = async () => {
    setStatus('loading');
    let res = await getCars().catch((err) => {
      console.error(err);
      message.error('Error fetching cars');
      setStatus('idle');
    });
    if (res) {
      setCars(res);
      setFilteredCars(res); // Initialize filtered cars
      setStatus('idle');
    }
  };

  // Handle the search query change and filter the cars
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    const filtered = cars.filter((car) =>
      car.name.toLowerCase().includes(query),
    );
    setFilteredCars(filtered); // Update the filtered list
  };

  const handleCancel = () => {
    setSelectedCars([]); // Clear selected cars when closing
    setVisible(false);
    setClickedUser(null);
  };

  const handleAssign = async () => {
    if (selectedCars.length === 0) {
      message.warning('Please select at least one car');
      return;
    }
    setAssigning(true); // Start loading state when assigning cars
    try {
      await assignCar(selectedCars, clickedUser.uid);
      message.success('Cars assigned successfully');
      setVisible(false);
      setSelectedCars([]);
    } catch (error) {
      message.error('Error assigning cars');
    } finally {
      setAssigning(false); // End loading state
      setClickedUser(null);
      fetchUsers();
    }
  };

  const handleCheckboxChange = (checkedValues) => {
    setSelectedCars(checkedValues);
  };

  return (
    <Modal
      title="Assign Car"
      visible={visible}
      onOk={handleAssign}
      onCancel={handleCancel}
      footer={null}
      className="flex flex-col"
    >
      {status === 'loading' ? (
        <div className="flex justify-center">
          <Spin />
        </div>
      ) : (
        <>
          {/* Search Input */}
          <Input
            placeholder="Search by car name"
            prefix={<SearchOutlined />}
            value={searchQuery}
            onChange={handleSearchChange}
            style={{ marginBottom: '20px' }}
          />

          {/* Checkbox group with filtered cars */}
          <Checkbox.Group
            style={{ width: '100%' }}
            onChange={handleCheckboxChange}
            defaultValue={clickedUser?.assignedCars || []}
          >
            {filteredCars.map((car) => (
              <div key={car.id}>
                <Checkbox value={car.id} className="car-checkbox">
                  <div className="flex items-center gap-3">
                    {/* Car Image */}
                    <img
                      src={car.images[0]}
                      alt={car.make}
                      style={{
                        width: '50px',
                        height: '50px',
                        borderRadius: '8px',
                      }}
                    />
                    {/* Car Details */}
                    <div>
                      <p className="font-semibold">{car.name}</p>
                    </div>
                  </div>
                </Checkbox>
              </div>
            ))}
          </Checkbox.Group>

          <div className="flex justify-center gap-3 mt-4">
            <Button
              onClick={handleCancel}
              className="hover:border-primary-color hover:text-primary-color"
            >
              Cancel
            </Button>
            <Button
              className="bg-primary-color hover:bg-primary-hover-color text-white hover:border-primary-color"
              loading={assigning} // Show loading when assigning
              onClick={handleAssign}
            >
              Assign
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default AssignCar;
